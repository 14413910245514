/*polyfill for async await funcction*/
/*import {async} from 'regenerator-runtime';*/
import { API_URL, RES_PER_PAGE, KEY } from './config.js';
import {AJAX } from './helpers.js';
// import {getJSON } from './helpers.js';
// import {sendJSON } from './helpers.js';
export const state ={
	recipe: {},
	search: {
		query :'',
		results : [],
		page: 1,
		resultsPerPage : RES_PER_PAGE, 
	},
	bookmarks: [],
};


const createRecipeObject = function(data)
{
	const { recipe } = data.data;
	return {
		id: recipe.id,
		title: recipe.title,
		publisher: recipe.publisher,
		sourceUrl: recipe.source_url,
		imageUrl: recipe.image_url,
		servings: recipe.servings,
		cookingTime: recipe.cooking_time,
		ingredients: recipe.ingredients,
		...(recipe.key && {key: recipe.key}),
	};

}
//load individual recipe
export const loadRecipe = async function(id)
{
	try {
		const data = await AJAX(`${API_URL}${id}?key=${KEY}`);
		state.recipe = createRecipeObject(data);
		
		if (state.bookmarks.some(bookmark => bookmark.id === id))
			state.recipe.bookmarked = true;
		else state.recipe.bookmarked = false;

		console.log("from loadRecipe", state.recipe);
	} 
	
	catch(err) {
		console.error("❤❤❤❤❤❤❤"+err);
		throw err;
	}
}

//load list of recipes of pizza in the left sidebar
export const loadSearchResults = async function(query)
{
	try {
		state.search.query = query;
		const data = await AJAX(`${API_URL}?search=${query}&key=${KEY}`);

		console.log("list of search results",data);

		state.search.results = data.data.recipes.map(rec => {
			return {
				id : rec.id,
				title: rec.title,
				publisher: rec.publisher,
				image: rec.image_url,
				...(rec.key && {key: rec.key}),
			};
		});
		state.search.page = 1;
		/*console.log(state.search.results);*/

	} catch(e) {
		console.error("❤❤❤❤❤❤❤"+e);
		throw e;
	}
}


//get how many search results per page on particalur page start and end index
export const getSearchResultsPage = function(page = state.search.page)
{

	state.search.page = page;
	const start = (page -1)* state.search.resultsPerPage; //0
	const end = page * state.search.resultsPerPage; //9
	console.log("Pages from to index", state.search.results.slice(start, end));
	return state.search.results.slice(start, end);
}

export const updateServings = function(newServings)
{
	state.recipe.ingredients.forEach(ing => {
		ing.quantity = (ing.quantity * newServings) / state.recipe.servings;
	//newQt = oldQt * newServings /oldServings
});
	state.recipe.servings = newServings;
}

const persistBookmarks = function(){
	localStorage.setItem('bookmarks', JSON.stringify(state.bookmarks));
}

export const addBookmark = function(recipe)
{
	state.bookmarks.push(recipe);
	if(recipe.id === state.recipe.id) state.recipe.bookmarked = true;
	persistBookmarks();
}

export const deleteBookmark = function (id){
	//Delete Bookmark
	const index = state.bookmarks.findIndex(el => el.id === id);
	state.bookmarks.splice(index, 1);

// Mark current recipe as not bookmarked
if(id === state.recipe.id) state.recipe.bookmarked = false;
persistBookmarks();
}

export const uploadRecipe = async function(newRecipe)

{
	try {
		console.log(Object.entries(newRecipe));

		const ingredients = Object.entries(newRecipe)
		.filter(entry => entry[0].startsWith('ingredient') && entry[1] !== '')
		.map(ing => {
			const ingArr = ing[1].split(',').map(el => el.trim());
			// ingArr = ing[1].replaceAll(' ','').split(',')
			if (ingArr.length !== 3) throw new Error('Wrong ingredient format! Please use the correct format ');
			const [quantity, unit, description] = ingArr ;   
			return {quantity: quantity ? +quantity : null , unit, description} 
		});

		const recipe = {
			title: newRecipe.title,
			source_url: newRecipe.sourceUrl,
			image_url: newRecipe.image,
			publisher:newRecipe.publisher,
			cooking_time: +newRecipe.cookingTime,
			servings: +newRecipe.servings,
			ingredients,
		};

		
		const data = await AJAX(`${API_URL}?key=${KEY}`, recipe);
		state.recipe = createRecipeObject(data);
		//console.log(data);
		addBookmark(state.recipe);
	} catch(e) {
		// statements
		throw e;
	}
	
}
const init = function ()
{
	const storage = localStorage.getItem('bookmarks');
	if(storage) state.bookmarks = JSON.parse(storage);
}
init()
console.log("Recipess in bookmark", state.bookmarks);