import * as model from './model.js';
import { MODAL_CLOSE_SEC } from './config.js';
import recipeView from './views/recipeView.js';
import searchView from './views/searchView.js';
import resultsView from './views/resultsView.js';
import bookmarksView from './views/bookmarksView.js';
import paginationView from './views/paginationView.js';
import addRecipeView from './views/addRecipeView.js';

/*These imports are for polyfills*/
/*import 'core-js/stable';
import 'regenerator-runtime/runtime';*/

/*if(module.hot)
{
	module.hot.accept();
}
*/

//API key to send data - 57b3b74e-9353-47e1-8561-f21f523d3cad
// https://forkify-api.herokuapp.com/v2
//https://forkify-api.herokuapp.com/api/v2/recipes/5ed6604591c37cdc054bc886

/*if there is id on the addressbar*/
const controlRecipes = async function () {
  try {
    const id = window.location.hash.slice(1);

    if (!id) return;

    recipeView.renderSpinner();

    //0) Updates results view to mark selected search results
    resultsView.update(model.getSearchResultsPage());

    //1)updating bookmarks View
    bookmarksView.update(model.state.bookmarks);

    // 2) Loading recipe
    await model.loadRecipe(id);
    /*const {recipe} = model.state;*/

    //3) Rendering Recipe
    recipeView.render(model.state.recipe);
  } catch (e) {
    recipeView.renderError();
  }
};

const controlSearchResults = async function () {
  try {
    resultsView.renderSpinner();

    //1) Get search query
    const query = searchView.getQuery();

    if (!query) return;

    //2) Load search results
    await model.loadSearchResults(query);

    //3)Render Results
    /*resultsView.render(model.state.search.results);*/
    resultsView.render(model.getSearchResultsPage());

    //4) Render Initial pagination buttons
    paginationView.render(model.state.search);
  } catch (err) {
    console.log(err + '✨✨✨✨✨ control search results');
  }
};

const controlPagination = function (goToPage) {
  resultsView.render(model.getSearchResultsPage(goToPage));
  paginationView.render(model.state.search);
};

const controlServings = function (newServings) {
  model.updateServings(newServings);
  /*recipeView.render(model.state.recipe);*/
  recipeView.update(model.state.recipe);
};
const controlAddBookmark = function () {
  //1) Add/remove bookmarks
  if (!model.state.recipe.bookmarked) model.addBookmark(model.state.recipe);
  else model.deleteBookmark(model.state.recipe.id);

  console.log('bookmark added/ deleted');
  console.log(model.state.recipe);

  //2) Update recipe view
  recipeView.update(model.state.recipe);

  //3)Render bookmarks
  bookmarksView.render(model.state.bookmarks);
};

const controlBookmarks = function () {
  bookmarksView.render(model.state.bookmarks);
};

const controlAddRecipe = async function (newRecipe) {
  try {
    // Show loading spinner
    addRecipeView.renderSpinner();

    // upload the new recipe data
    await model.uploadRecipe(newRecipe);
    console.log(model.state.recipe);

    // Render recipe
    recipeView.render(model.state.recipe);

    // Success Message
    addRecipeView.renderMessage();

	// Render bookmarks view
	bookmarksView.render(model.state.bookmarks);

	// Change ID in URL
	window.history.pushState(null, '', `#${model.state.recipe.id}`);

    // Close form window
    setTimeout(function () {
      addRecipeView.toggleWindow();
    }, MODAL_CLOSE_SEC * 1000);
  } catch (e) {
    console.log('💕💕💕💕💕', e);
    addRecipeView.renderError(e.message);
  }
};
const init = function () {
  bookmarksView.addHandlerRender(controlBookmarks);
  recipeView.addHandlerRender(controlRecipes);
  recipeView.addHandlerUpdateServings(controlServings);
  searchView.addHandlerSearch(controlSearchResults);
  paginationView.addHandlerClick(controlPagination);
  recipeView.addHandlerAddbookmark(controlAddBookmark);
  addRecipeView.addHandlerUpload(controlAddRecipe);
};
init();

/*const clearBoomkmarks = function ()
{
	localStorage.clear('bookmarks');
}
clearBoomkmarks();*/
