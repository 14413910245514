import icons from 'url:../../img/icons.svg'; // version2 parcel use this syntax for image url
/*import {Fraction} from 'fractional';*/
import fracty from "fracty";
import View from './View.js';
/*console.log(Fraction);*/
class RecipeView extends View{

	_parentElement = document.querySelector('.recipe');
	
	_errorMessage = 'We could not find that recipe. Please try another one!'
	_message = '';


addHandlerRender(handler)
{
	/*['hashchange', 'load'].forEach(ev => window.addEventListener(ev, handler));*/
	window.addEventListener('hashchange', handler);
	window.addEventListener('load', handler);
}

addHandlerUpdateServings (handler)
{
	this._parentElement.addEventListener('click', function(e)
	{
		const btn = e.target.closest('.btn--update-servings');
		if(!btn) return;
		console.log(btn);
		/*const updateTo = +btn.dataset.updateTo;*/
		const {updateTo} = btn.dataset;
		if(+updateTo > 0){
		handler(+updateTo);	
		}
		
	});
}

addHandlerAddbookmark(handler)
{
 this._parentElement.addEventListener('click', function(e){
 	const btn = e.target.closest('.btn--bookmark');
 	if (!btn) return;
 	handler();
 });
}


_generateMarkup(){
	return `      
	<figure class="recipe__fig">
	<img src="${this._data.imageUrl}" alt="Tomato" class="recipe__img" />
	<h1 class="recipe__title">
	<span>${this._data.title}</span>
	</h1>
	</figure>

	<div class="recipe__details">
	<div class="recipe__info">
	<svg class="recipe__info-icon">
	<use href="${icons}#icon-clock"></use>
	</svg>
	<span class="recipe__info-data recipe__info-data--minutes">${this._data.cookingTime}</span>
	<span class="recipe__info-text">minutes</span>
	</div>
	<div class="recipe__info">
	<svg class="recipe__info-icon">
	<use href="${icons}#icon-users"></use>
	</svg>
	<span class="recipe__info-data recipe__info-data--people">${this._data.servings}</span>
	<span class="recipe__info-text">SERVINGS</span>

	<div class="recipe__info-buttons">
	<button class="btn--tiny btn--update-servings" data-update-to = "${this._data.servings -1 }">
	<svg>
	<use href="${icons}#icon-minus-circle"></use>
	</svg>
	</button>
	<button class="btn--tiny btn--update-servings" data-update-to = "${this._data.servings +1 }">
	<svg>
	<use href="${icons}#icon-plus-circle"></use>
	</svg>
	</button>
	</div>
	</div>

	<div class="recipe__user-generated ${this._data.key ? '' : 'hidden'}">
	<svg >
	<use href="${icons}#icon-user"></use>
	</svg>
	</div>
	<button class="btn--round btn--bookmark">
	<svg class="">
	<use href="${icons}#icon-bookmark${this._data.bookmarked ? '-fill' : ''}"></use>
	</svg>
	</button>
	</div>

	<div class="recipe__ingredients">
	<h2 class="heading--2">Recipe ingredients</h2>
	<ul class="recipe__ingredient-list">

	${this._data.ingredients.map(this._generateMarkupIngredient).join('')}

	</ul>
	</div>

	<div class="recipe__directions">
	<h2 class="heading--2">How to cook it</h2>
	<p class="recipe__directions-text">
	This recipe was carefully designed and tested by
	<span class="recipe__publisher">${this._data.publisher}</span>. Please check out
	directions at their website.
	</p>
	<a
	class="btn--small recipe__btn"
	href="${this._data.sourceUrl}"
	target="_blank"
	>
	<span>Directions</span>
	<svg class="search__icon">
	<use href="${icons}#icon-arrow-right"></use>
	</svg>
	</a>
	</div>
	`;

}

/*************/
_generateMarkupIngredient(ing)
{
	{
		return `<li class="recipe__ingredient">
		<svg class="recipe__icon">
		<use href="src/img/icons.svg#icon-check"></use>
		</svg>
		<div class="recipe__quantity">${ing.quantity ?  fracty(ing.quantity).toString() : ''}</div>
		<div class="recipe__description">
		<span class="recipe__unit">${ing.unit}</span>
		${ing.description}
		</div>
		</li>`;
	}
}
};


export default new RecipeView();
