import icons from 'url:../../img/icons.svg';
import View from './View.js';

class PaginationView extends View
{

	_parentElement = document.querySelector('.pagination');


	//publish
	addHandlerClick(handler)
	{
		this._parentElement.addEventListener('click', function(e)
		{
			const btn = e.target.closest('.btn--inline');
			console.log(btn);
			if (!btn) return;
			const goToPage = +btn.dataset.goto;
			/*console.log(goToPage);*/
			handler(goToPage);
		})
	}

	_generateMarkup(){
		const curPage = this._data.page;
		const numPages = Math.ceil(this._data.results.length / this._data.resultsPerPage);
		console.log("number of pages", numPages);
		//Page 1 , and there are other pages
		if (curPage === 1 && numPages >1) {
			return ` 
				<button data-goto ="${curPage +1 }" class="btn--inline pagination__btn--next">
					<span>Page ${curPage +1 }</span>
					<svg class="search__icon">
					<use href="${icons}#icon-arrow-right"></use>
					</svg>
				</button>
			`;
		}

		

		//Last Page
		if (curPage === numPages && numPages >1) {
			return `
		<button data-goto ="${curPage - 1 }" class="btn--inline pagination__btn--prev">
            <svg class="search__icon">
              <use href="${icons}#icon-arrow-left"></use>
            </svg>
            <span>Page ${curPage - 1}</span>
        </button>

			`;
		}
		//Other pages
		if (curPage < numPages ) {
			return `
		<button data-goto ="${curPage - 1 }" class="btn--inline pagination__btn--prev">
            <svg class="search__icon">
              <use href="${icons}#icon-arrow-left"></use>
            </svg>
            <span>Page ${curPage - 1}</span>
        </button>
        <button data-goto ="${curPage +1 }" class="btn--inline pagination__btn--next">
					<span>Page ${curPage +1 }</span>
					<svg class="search__icon">
					<use href="${icons}#icon-arrow-right"></use>
					</svg>
				</button>

			`;
		}
		//Page 1 , and there are no other pages, only one page
		return '';

     }
}

export default new PaginationView()